import React, { useEffect, useState } from 'react';
import useFavourite from '../hooks/useFavourite';
import { AccountPageContext, FormattedUserFavourite } from './account/interfaces';
import { formatAccountData } from './account/accont.util';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import FavoriteDetails from '../components/account/favorite-details/FavoriteDetails';
import styles from './favorite-list-share.module.scss';

interface Props {
  location: Location;
  pageContext: AccountPageContext;
}

const FavouriteListShare: React.FC<Props> = ({ pageContext, location }: Props) => {
  const favouritesAPI = useFavourite();
  const favouriteId = Number(new URLSearchParams(location.search).get('id'));
  const [favourite, setFavourite] = useState<FormattedUserFavourite | null>(null);

  useEffect(() => {
    favouritesAPI.fetchPublicFavourite(favouriteId).then((fav) => {
      const formattedData = formatAccountData(pageContext, [fav]);
      setFavourite(formattedData.favourites[0]);
    });
  }, []);

  return (
    <Layout isWhiteBackground={true}>
      <SEO title={'Favorite List'} />
      <div className={styles.FavoriteListShare}>
        {favourite && (
          <FavoriteDetails favouriteAPI={favouritesAPI} favourite={favourite} isPublic={true} />
        )}
      </div>
    </Layout>
  );
};

export default FavouriteListShare;
